<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <a-button size='small' @click="()=>{$router.go(-1)}">
        <LeftOutlined />返回
      </a-button>
      <span class="ml15 card_title">品牌商详情</span>
    </a-card>
    <a-card>
      <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="1" header="基础信息">
          <div class="s_flex_ali mb20" v-if='moreData.company'>
            <p class="s_wid8 s_c333 s_text_r">公司名称：</p>
            <p class='s_pal20 s_c333'>{{moreData.company}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.brand_phone'>
            <p class="s_wid8 s_c333 s_text_r">手机号：</p>
            <p class='s_pal20 s_c333'>{{moreData.brand_phone}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.brand_name'>
            <p class="s_wid8 s_c333 s_text_r">联系人：</p>
            <p class='s_pal20 s_c333'>{{moreData.brand_name}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.account'>
            <p class="s_wid8 s_c333 s_text_r">账号：</p>
            <p class='s_pal20 s_c333'>{{moreData.account}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.address_text'>
            <p class="s_wid8 s_c333 s_text_r">详细地址：</p>
            <p class='s_pal20 s_c333'>{{moreData.address_text}}</p>
          </div>
          <!-- <div class="s_flex_ali mb20" v-if='moreData.website_url'>
            <p class="s_wid8 s_c333 s_text_r">官方网站：</p>
            <p class='s_pal20 s_c333'>{{moreData.website_url}}</p>
          </div> -->
          <div class="s_flex_ali mb20" v-if='moreData.industry'>
            <p class="s_wid8 s_c333 s_text_r">所属行业：</p>
            <p class='s_pal20 s_c333'>{{moreData.industry}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.email'>
            <p class="s_wid8 s_c333 s_text_r">E-Mail：</p>
            <p class='s_pal20 s_c333'>{{moreData.email}}</p>
          </div>
          <!-- <div class="s_flex_ali mb20" v-if='moreData.environment'>
            <p class="s_wid8 s_c333 s_text_r">访问环境：</p>
            <p class='s_pal20 s_c333'>{{moreData.environment}}</p>
          </div> -->
          <div class="s_flex_ali mb20" v-if='moreData.wechat_auth_text'>
            <p class="s_wid8 s_c333 s_text_r">微信公众号授权：</p>
            <p class='s_pal20 s_c333'>{{moreData.wechat_auth_text}}</p>
          </div>
          <!-- <div class="s_flex_ali mb20">
            <p class="s_wid8 s_c333 s_text_r">微信小程序授权：</p>
            <p class='s_pal20 s_c333'>{{moreData.environment}}</p>
          </div> -->
          <div class="s_flex_ali mb20" v-if='moreData.status_text'>
            <p class="s_wid8 s_c333 s_text_r">状态：</p>
            <p class='s_pal20 s_c333'>{{moreData.status_text}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.signing_time'>
            <p class="s_wid8 s_c333 s_text_r">有效时间：</p>
            <p class='s_pal20 s_c333'>{{moreData.signing_time}}</p>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="功能信息">
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item label="已订购功能模块">
              <a-table class="ml15" :columns="columns" :data-source="data" bordered :pagination='false' v-if="moreData.app_is_super==0"></a-table>
              <div v-else class="s_c999">全包模式</div>
            </a-form-item>
          </a-form>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="账号资金">
          <a-descriptions  bordered>
            <a-descriptions-item label="账号余额">{{moreData.balance}}</a-descriptions-item>
            <a-descriptions-item label="在途金额">{{moreData.way_balance||0}}</a-descriptions-item>
          </a-descriptions>
        </a-collapse-panel>
        <a-collapse-panel key="4" header="审核信息" v-if='moreData.init_audit_status !== 0'>
          <div class="s_flex_ali mb20">
            <p class="s_wid8 s_c333 s_text_r">审核状态：</p>
            <p class='s_pal20 s_c333'>{{moreData.init_audit_status_text}}</p>
          </div>
          <div class="s_flex_ali mb20" v-if='moreData.init_audit_status == -1'>
            <p class="s_wid8 s_c333 s_text_r">原因：</p>
            <p class='s_pal20 s_c333'>{{moreData.init_audit_remark}}</p>
          </div>
          <div class="s_flex_ali mb20">
            <p class="s_wid8 s_c333 s_text_r">资质认证：</p>
            <div class='s_pal20 s_c333 s_flex_ali'>
              <a-image
                v-for="(v) in moreData.license_images" :key="v"
                :width="100"
                :src="info.picdomain+'/'+v"
                class="m-r-10"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-card>
  </a-layout>
</template>

<script>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import { getBrandtMore, getAuditMore } from "@/api/brand";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import store from "@/store";
export default {
  components: {
    LeftOutlined,
  },
  setup() {
    const state = reactive({
      info: store.state.user.info,
      moreData: {},
      labelCol: { span: 2 },
      wrapperCol: { span: 14 },
      activeKey: ["1", "2", "3"],
    });
    let data = ref([]);
    let columns = [
      {
        title: "功能模块",
        dataIndex: "app_name",
        key: "app_name",
      },
      {
        title: "费用/年",
        dataIndex: "real_price",
        align: "center",
        key: "real_price",
      },
      {
        title: "到期时间",
        dataIndex: "expire_time_text",
        align: "center",
        //   customRender: ({ text, index }) => {
        //     const obj = {
        //       children: text,
        //       props: {},
        //     };
        //     if (index === 0) {
        //       obj.props.rowSpan = data.value.length;
        //     }else{
        //       obj.props.rowSpan = 0;
        //     }
        //     return obj;
        //   },
      },
      {
        title: "剩余天数",
        dataIndex: "rest_day_text",
        align: "center",
        // customRender: ({ text, index }) => {
        //   const obj = {
        //     children: text,
        //     props: {},
        //   };
        //   if (index === 0) {
        //     obj.props.rowSpan = data.value.length;
        //   }else{
        //     obj.props.rowSpan = 0;
        //   }
        //   return obj;
        // },
      },
    ];
    const $route = inject("$route");
    const $Modal = inject("$Modal");
    const $router = inject("$router");
    const getMore = async () => {
      const hide = message.loading("正在加载中...", 0);
      var res;
      if ($route.query.type == "list") {
        res = await getBrandtMore($route.query.id).then((res) => res.data).catch(error => error);
      } else {
        res = await getAuditMore($route.query.id).then((res) => res.data).catch(error => error);
      }
      if ($iscode(res)) {
        state.moreData = res.data;
        data.value = res.data.apps;
        setTimeout(hide);
      } else {
        message.error(res.msg);
        setTimeout(hide);
      }
    };
    onMounted(() => {
      getMore();
    });
    return {
      ...toRefs(state),
      columns,
      data,
    };
  },
};
</script>
<style>
.levelBox {
  width: 100%;
  padding: 15px 20px;
  background-color: #f7f7f7;
  margin-left: 15px;
  box-sizing: border-box;
}
</style>
